import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Layout from "../../../components/Layout"
import styled from "styled-components"
import { Helmet } from 'react-helmet'

import TextRainbow from "../../../components/TextRainbow"

import MetaToolsCertstream from "../../../images/meta-tools-certstream.png";

// import PhishingDetector from "./PhishingDetector"

interface IBrands {
    label: string;
    bgColor: string;
    domains: string[];
    allowedStrats: string[]
}

interface IStrategy {
    label: string;
}

interface IBrandButton {
    bgColor: string;
    selected: boolean;
}

interface IStratButton {
    selected: boolean;
}

interface ISelectedBrand {
    visible: boolean;
}

const Brands: IBrands[] = [{
    label: "test",
    bgColor: "#e680e2",
    domains: [],
    allowedStrats: [
        "basic"
    ]
}, {
    label: "crypto terms",
    bgColor: "#e6e680",
    domains: [
        'azuki',
        'binance',
        'bitcoin',
        'boredape',
        'coinbase',
        'ether',
        'ethereum',
        'makerdao',
        'metamask',
        'moonbirds',
        'mycrypto',
        'myetherwallet',
        'opensea',
        'satoshi',
        'uniswap',
        'vitalik',
        'walletconnect',
    ],
    allowedStrats: [
        "basic"
    ]
}, { 
    label: "metamask",
    bgColor: "#e2761b",
    domains: [
        "metamask.io",
        "support.metamask.io"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "mycrypto",
    bgColor: "#163151",
    domains: [
        "mycrypto.com",
        "app.mycrypto.com",
        "support.mycrypto.com"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "binance",
    bgColor: "#f3ba2f",
    domains: [
        "binance.com"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "myetherwallet",
    bgColor: "#24C0A9",
    domains: [
        "myetherwallet.com"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "walletconnect",
    bgColor: "#2b6cb0",
    domains: [
        "walletconnect.com"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "consensys",
    bgColor: "#000",
    domains: [
        "consensys.net"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "coinbase",
    bgColor: "#4169e1",
    domains: [
        "coinbase.com",
        "pro.coinbase.com"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "opensea",
    bgColor: "#2081E2",
    domains: [
        "opensea.io"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "looksrare",
    bgColor: "#2DE370",
    domains: [
        "looksrare.org"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}, {
    label: "trustwallet",
    bgColor: "#105BAA",
    domains: [
        "trustwallet.com"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
},  {
    label: "uniswap",
    bgColor: "#FF007A",
    domains: [
        "uniswap.org",
        "uniswap.exchange"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
},  {
    label: "galaxis",
    bgColor: "#000",
    domains: [
        "galaxis.xyz",
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
},  {
    label: "ledger",
    bgColor: "#fe5301",
    domains: [
        "ledger.com",
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
},  {
    label: "Optimism",
    bgColor: "#ff0420",
    domains: [
        "optimism.io",
        "app.optimism.io",
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
},  {
    label: "Gnosis",
    bgColor: "#008C73",
    domains: [
        "gnosis-safe.io",
        "gnosis.io",
        "gnosis-safe"
    ],
    allowedStrats: [
        "basic",
        // "metamask/eth-phishing-detect(3)"
    ]
}]

const Strategy: IStrategy[] = [{
    label: "basic"
}];
// }, {
//     label: "metamask/eth-phishing-detect(3)"

const Title = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`
const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const SelectedBrand = styled.div<ISelectedBrand>`
    background: #000;
    padding: 0.25em;
    width: fit-content;
    margin: 1em 0;
    display: ${props => props.visible ? "block" : "none"};
`
const BrandButton = styled.div<IBrandButton>`
    border: 1px solid #999;
    border-radius: 5%;
    padding: 0.25em;
    display: inline-block;
    margin: 0.25em;
    width: fit-content;
    background: ${props => props.bgColor};
    opacity: ${props => props.selected ? 1 : 0.4};

    &:hover {
        cursor: pointer;
    }
`
const StrategyButton = styled.div<IStratButton>`
    border: 1px solid #999;
    border-radius: 5%;
    padding: 0.25em;
    display: inline-block;
    margin: 0.25em;
    width: fit-content;
    background: ${props => props.selected ? "#000" : "#212121"};
    opacity: ${props => props.selected ? 1 : 0.4};

    &:hover {
        cursor: pointer;
    }
`
const OptionContainer = styled.div`
    margin: 0.75em 0;
`
const DisconnectButton = styled.div`
    background: #e68080;
    padding: 0.25em;
    width: fit-content;
    margin: 0.5em;
    color: #212121;
    font-weight: 800;

    &:hover {
        cursor: pointer;
    }
`
const ResultsContainer = styled.div`
    padding: 0.5em;
    font-family: VT323, monospace;
    font-size: 80%;
    width: 100%;
    background: #000;
    height: 500px;
    max-height: 500px;
    overflow-y: scroll;
`
const ResultsListContainer = styled.ul`
    margin: 0 0 0 2em;
    font-family: monospace;
`

const BannerConnect = `
    color: #1fde30;
`
const BannerDisconnect = `
    color: #de1f23;
`
const BannerRety = `
    color: #dade1f;
`

const Certstream = () => {
    const [chosenBrand, setChosenBrand] = useState<null | IBrands>(null)
    const [chosenStrat, setChosenStrat] = useState<null | IStrategy>(null)
    const [isForceDisconnect, setIsForceDisconnect] = useState<boolean>(false)

    const RefResultsList = React.useRef() as React.MutableRefObject<HTMLUListElement>;
    const RefAmountChecked = React.useRef() as React.MutableRefObject<HTMLDivElement>;

    let results: string[] = [];
    let intChecked: number = 0;

    const waitForClosedSocket = (socket: any) => {
        return new Promise((resolve, _reject) => {
            while (socket.readyState === socket.OPEN) { /* no-op */ }
            return resolve(null)
          })
    }

    useEffect(() => {
        // We have no options, close.
        if((chosenBrand === null || chosenStrat === null)) {
            return;
        }

        const sock = connectToWebSocket();

        return () => {
            // maybe force close ws
            sock.then((sock) => {
                // ?
            })
        };

    }, [chosenBrand, chosenStrat, isForceDisconnect]);

    const connectToWebSocket = async() => {
        console.log(`@@@ Attempting to connect to WebSocket`)
        const socket = new WebSocket("wss://certstream.calidog.io/");

        socket.onopen = function(e) {
            console.log("[open] Certstream connection made", e)
            const banner = document.createElement("div");
            banner.innerText = `[+] WebSocket connection made to Certstream.`;
            banner.style.cssText = BannerConnect;
            RefResultsList.current?.append(banner)
        }

        socket.onclose = async function(e) {
            await waitForClosedSocket(socket)

            console.log("[closed] Certstream connection ended", e)
            const banner = document.createElement("div");
            banner.innerText = `[-] WebSocket connection terminated.`;
            banner.style.cssText = BannerDisconnect;
            RefResultsList.current?.append(banner)

            // Attempt to retry connection
            const rety = document.createElement("div");
            rety.innerText = `[/] Attempting to reopen WebSocket.`;
            rety.style.cssText = BannerRety;
            RefResultsList.current?.append(rety)
            setTimeout(() => {
                connectToWebSocket();
            }, 5000)
        }

        socket.onerror = function(error) {
            console.log(`Error!`, error);

            const banner = document.createElement("div");
            banner.innerText = `[~] WebSocket Error! ${JSON.stringify(error)}`;
            banner.style.cssText = BannerDisconnect;
            RefResultsList.current?.append(banner)
         };

         monitorCertStream(socket)
    };

    const monitorCertStream = (socket: any) => {
        if(!socket) {
            return;
        }

        socket.onmessage = async function(event: any) {
            ++intChecked;
            if(chosenBrand && chosenStrat && event && event?.data) {
                const jsonobj = JSON.parse(event.data);
                if(jsonobj) {
                    let domain = jsonobj.data.leaf_cert.subject.CN;

                    domain = domain && domain?.toString() || ""
                    domain = domain.replace("\*\.", "")

                    if(domain === null || domain === "") {
                        return false;
                    }

                    console.log(domain)

                    switch(chosenStrat?.label ?? "basic") {
                        case 'basic' :
                        default :
                            if (domain.indexOf(chosenBrand.label) != -1){
                                addResult(domain)
                            }

                            chosenBrand.domains.forEach((whitelistedDomain) => {
                                if(domain.indexOf(whitelistedDomain) != -1) {
                                    addResult(domain)
                                    return true;
                                }
                            })
                        break;
                        // case 'metamask/eth-phishing-detect(3)' :

                        //     const whitelist = chosenBrand.domains;
                        //     const blacklist = [0];
                        //     const fuzzylist = chosenBrand.domains;
                        //     const tolerance = 3;

                        //     const detector = new PhishingDetector({ whitelist, blacklist, fuzzylist, tolerance })
                        //     const value = detector.check(domain)
                            
                        //     if(value.result) {
                        //         addResult(domain)
                        //         return true;
                        //     }
                        // break;
                    }
                }
            }

           RefAmountChecked!.current!.textContent = `Checked ${intChecked} domains.`;
        }
    }

    const addResult = (domain: string) => {

        if(results.includes(domain)) {
            console.warn(`Domain ${domain} already added`)
            return;
        }

        results.push(domain)

        const entry = document.createElement("li");
        entry.innerText = domain;

        console.log(`Added ${domain}`, results)
        RefResultsList.current?.append(entry)



        //entry.scrollIntoView();
    }

    const renderResults = () => {

        if(!chosenBrand || !chosenStrat) {
            return;
        }

        return(
            <>
                <ResultsContainer>
                    <ResultsListContainer ref={RefResultsList}></ResultsListContainer>
                </ResultsContainer>
                <div ref={RefAmountChecked}></div>
            </>
        )
    }

    return (
        <Layout>
            <Helmet>
              <title>CertStream Crypto | Harry Denley</title>
              <meta name="description" content="Hunt for crypto-related scams using CertStream! Use your computing power to mine for phishing scams!" />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://harrydenley.com/tools/certstream" />
            <meta property="og:title" content="CertStream Crypto - Harry Denley" />
            <meta property="og:description" content="Hunt for crypto-related scams using CertStream!" />
            <meta property="og:image" content={`https://harrydenley.com${MetaToolsCertstream}`} />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@sniko_" />
              <meta name="twitter:title" content={`CertStream Crypto - Harry Denley`} />
              <meta name="twitter:description" content={`Hunt for crypto-related scams using CertStream! Use your computing power to mine for phishing scams!`} />
              <meta name="twitter:image" content={`https://harrydenley.com/${MetaToolsCertstream}`} />
          </Helmet>
            <Title>
                <Link to="/">~/</Link>
                <Link to="/tools">tools/</Link>&nbsp;Certstream
            </Title>
            <SubTitle>
                A tool to run some basic analysis on newly registered SSL certificates.
            </SubTitle>
            <SubTitle>
                This tool makes use of CertStream (By CaliDog) to consume a live feed of newly registered{' '}
                SSL certificates and run some basic analysis on the domains to see if they should be of{' '}
                interest to investigate. <br /><br />
                The domains that return `true` on logic checks will still need to be investigated.
            </SubTitle>

            <SelectedBrand visible={chosenBrand !== null}>
                Monitoring for: <TextRainbow text={chosenBrand?.label ?? ""} /> ({chosenStrat && `Strat: ${chosenStrat?.label ?? ""}`}) <br /><br />
                <small>
                    {chosenBrand?.domains.map((d) => {
                        return(<>{d}<br /></>)
                    })}
                </small>
            </SelectedBrand>

            {!chosenBrand && !chosenStrat && 
                <OptionContainer>
                    <SubTitle>
                        Select a brand to monitor: 
                    </SubTitle>
                    { 
                        Brands.map((brand: IBrands) => {
                            return(
                                <BrandButton 
                                    bgColor={brand.bgColor}
                                    selected={(chosenBrand !== null && (chosenBrand as IBrands).label === brand.label) as boolean}
                                    onClick={() => setChosenBrand(brand)}
                                >
                                {brand.label}
                                </BrandButton>
                            )
                        })
                    }
                </OptionContainer>
            }

            {chosenBrand && !chosenStrat &&
                <OptionContainer>
                    <SubTitle>
                        Select a strategy 
                    </SubTitle>
                    { 
                        Strategy.map((strat: IStrategy) => {
                            if(chosenBrand.allowedStrats.includes(strat.label)) {
                                return(
                                    <StrategyButton 
                                        selected={(chosenStrat !== null && (chosenStrat as IStrategy).label === strat.label) as boolean}
                                        onClick={() => setChosenStrat(strat)}
                                    >
                                        {strat.label}
                                    </StrategyButton>
                                )
                            }
                        })
                    }
                </OptionContainer>
            }

            {renderResults()}

            {chosenBrand !== null && chosenStrat !== null && !isForceDisconnect && 
                <DisconnectButton onClick={() => location.reload()}>Disconnect</DisconnectButton>
            }
        </Layout>
    );
};

export default Certstream;
